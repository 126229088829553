@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

#input-label {
  display: none;
}

.input-wrap {
  font-family: Oxygen, "MuseoSansW01-Rounded500" !important;
  width: 100%;
  height: 1.1876em;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 0 1rem;
  border-radius: 0.5rem;
  background: #fff;
  border: 2px solid #eee;
  box-sizing: border-box;
}

.input-wrap i {
  font-size: 1.5rem;
  color: white;
  font-family: Oxygen, "MuseoSansW01-Rounded500" !important;
}

#product-search {
  font-size: 1rem;
  color: #3a3a3a;
  width: 100%;
  height: 100%;
  padding: 0 0 0 1rem;
  /* background:  #e8deec;; */
  border: none;
  outline: none;
}

#product-search::placeholder {
  color: #3a3a3a;
  opacity: 0.7;
}

.fa-times:hover {
  cursor: pointer;
}

.results-wrap {
  width: 50%;
  min-height: 11rem;
  max-height: auto;
  margin-top: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

.list-item {
  display: inline-flex;
  word-wrap: initial;
  list-style-type: none;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-right: 0.5rem;
}

.list-item a {
  color: grey;
  text-decoration: none;
}

.list-item a:hover {
  color: #fff;
  transition: 200ms ease-in;
}

@media (max-width: 768px) { 
  .results-wrap {
    width: 96%;
  }
}
