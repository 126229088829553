@charset "utf-8";
@font-face {
  font-family: "MuseoSansW01-Rounded300";
  src: url("../Fonts/942394/b306383f-db79-42c0-92db-e0759aff6588.eot?#iefix");
  src: url("../Fonts/942394/b306383f-db79-42c0-92db-e0759aff6588.eot?#iefix")
      format("eot"),
    url("../Fonts/942394/66c80723-12ac-4a96-8303-d0eee61c32b1.woff2")
      format("woff2"),
    url("../Fonts/942394/36d5ed39-d8cd-4558-a597-0f47565cdd0e.woff")
      format("woff"),
    url("../Fonts/942394/5b6ee6f2-5a3c-4f51-a192-9d34b20b76d7.ttf")
      format("truetype");
}
@font-face {
  font-family: "MuseoSansW01-Rounded500";
  src: url("../Fonts/942397/bce92934-3424-455e-a932-b365b0acb769.eot?#iefix");
  src: url("../Fonts/942397/bce92934-3424-455e-a932-b365b0acb769.eot?#iefix")
      format("eot"),
    url("../Fonts/942397/724577a4-2ce2-4c97-94cb-9a622b9ab1c3.woff2")
      format("woff2"),
    url("../Fonts/942397/231821bd-27ad-42c9-9c65-a4d8465cef7a.woff")
      format("woff"),
    url("../Fonts/942397/f95819e8-3fc1-420e-af97-c57b8f3b41fa.ttf")
      format("truetype");
}

/* Reset */
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Css Clearfix */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}

/* General */
* {
  outline: none !important;
  border-radius: 0;
}
a,
.container,
.logo img {
  transition: all 0.3s;
}
html,
body {
  height: 100%;
}
body {
  min-width: 320px;
  background-color: #f5f5f5;
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  color: #3c3c3c;
  position: relative;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
.wrapper {
  /* transition: padding-left ease 0.3s;
  min-height: 100%;
  padding-top: 0;
  padding-bottom: 65px;
  padding-left: 210px; */
}

.nav-collapsed .wrapper {
  padding-left: 65px;
}
a {
  text-decoration: none;
  display: inline-block;
}
a:hover,
a:focus,
a:active,
a.active {
  text-decoration: underline;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}
strong {
  font-weight: 700;
}
h1,
h2,
h3,
h4 {
  font-family: "MuseoSansW01-Rounded500", "PT Sans", sans-serif;
  font-weight: normal;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.row.small-gutter {
  margin-left: -8px;
  margin-right: -8px;
}
.row.small-gutter > div {
  padding-left: 8px;
  padding-right: 8px;
}

/* Form Control Custom
--------------------------------------------------------------------------------------------------------*/
.form-group {
  margin-bottom: 13px;
}
.form-group label {
  margin-bottom: 7px;
  line-height: 1.2;
}
.form-control {
  border-radius: 3px;
  box-shadow: none !important;
  border: solid 1px #d8d8d8;
  height: 48px;
  padding: 12px 10px;
  font-size: 14px;
}
/* .btn {
  border-radius: 3px;
  padding: 13px 20px 11px 20px;
  font-size: 14px;
  font-family: "MuseoSansW01-Rounded500";
} */
.btn-primary,
.btn-secondary {
  /* min-width: 80px; */
}
textarea.form-control {
  height: auto;
}
.form-control:disabled,
.form-control[readonly] {
  background: #f9f9f9;
  border-color: #d8d8d8;
}
.is-invalid {
  background-color: #fef3f3 !important;
  background-image: none !important;
  border-color: #f40000 !important;
}
.invalid-feedback {
  color: #f40000;
  font-size: 13px;
}
.is-valid {
  background-color: #f2f9ec !important;
  background-image: none !important;
  border-color: #7dc242 !important;
}
.valid-feedback {
  color: #7dc242;
  font-size: 13px;
}
.form-control:disabled,
.form-control[readonly] {
  opacity: 0.6;
  background-color: #fff;
}
a.btn {
  text-decoration: none;
}
.primary-out {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.2s;
}
.primary-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.primary-out:hover,
.primary-out:focus,
.primary-out:active {
  color: #fff;
}
.primary-out:hover:before,
.primary-out:active:before {
  transform: scale(1);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  opacity: 0.4;
}
.secondary-out {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #efefef;
  border-color: #efefef !important;
  transition-property: color;
  transition-duration: 0.2s;
}
.secondary-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e8e8e8;
  border-color: #ededed !important;
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.secondary-out:hover,
.secondary-out:focus,
.secondary-out:active {
  background: #efefef;
  color: white;
}
.secondary-out:hover:before,
.secondary-out:active:before {
  transform: scale(1);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #94979b;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background: #efefef;
}
.ic-btn {
  padding-left: 42px;
  position: relative;
}
.ic-btn img {
  position: absolute;
  left: 14px;
  top: 15px;
  height: 16px;
}
.ic-btn.disabled img {
  display: none;
}

/* Page Wrapper
--------------------------------------------------------------------------------------------------------*/
.page-wrapper {
  display: block;
  width: 100%;
  table-layout: fixed;
  min-height: 100%;
  position: relative;
}
.nav-collapsed .page-wrapper {
  display: table;
  height: 100%;
}

/* Login Page
--------------------------------------------------------------------------------------------------------*/
.login-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
}
.login-outer {
  display: table-cell;
  vertical-align: middle;
  padding: 15px 15px 80px 15px;
  height: 100%;
}
.loginbox {
  position: relative;
  padding: 40px;
  background-color: #fff;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}
.loginbox:before {
  content: "";
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.loginlogo {
  text-align: center;
  padding: 0 0 30px 0;
}
.loginlogo img {
  max-width: 100%;
}
.loginbox .btn {
  font-size: 16px;
  padding: 11px 20px 9px 20px;
}
.login-info-text {
  color: #94979b;
  padding: 13px 0 0 0;
}
.login-wrapper .footer {
  left: 0 !important;
}
.loginbox h2 {
  font-size: 24px;
}
.loginbox .back-button {
  z-index: 10;
  position: absolute;
  left: 10px;
  top: 15px;
  background-color: #fff;
  border-color: #fff;
}

/* 404 Page
--------------------------------------------------------------------------------------------------------*/
.error-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
}
.error-outer {
  display: table-cell;
  vertical-align: middle;
  padding: 15px 15px 80px 15px;
  height: 100%;
}
.error-block {
  text-align: center;
  position: relative;
  max-width: 527px;
  margin: 0 auto;
}
.error-wrapper .footer {
  left: 0 !important;
}
.errorlogo {
  text-align: center;
  padding: 0 0 80px 0;
}
.errorlogo img {
  max-width: 100%;
}
.error-block h1 {
  padding: 50px 0 25px 0;
  margin: 0;
  font-size: 32px;
}
.error-block p {
  padding: 0 0 25px 0;
  margin: 0;
  color: #666;
}
.listing {
  list-style: none;
  padding: 0 0 15px 0;
  margin: 0;
}
.listing li {
  list-style-type: none;
  padding: 0 0 0 25px;
  margin: 0 0 8px 0;
  position: relative;
}
.listing li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  background-color: #666666;
  height: 7px;
  width: 7px;
  border-radius: 10px;
}
.msg-popup {
  padding: 30px;
}
.msg-popup p {
  font-size: 18px;
  margin: 0;
  padding: 0 0 30px 0;
}

/* CSS Loader
--------------------------------------------------------------------------------------------------------*/
.loader-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(215, 215, 215, 0.8);
  z-index: 9999;
}
.cssload-fond {
  margin: auto;
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}
.cssload-container-general {
  animation: cssload-animball_two 2.2s infinite;
  -o-animation: cssload-animball_two 2.2s infinite;
  -ms-animation: cssload-animball_two 2.2s infinite;
  -webkit-animation: cssload-animball_two 2.2s infinite;
  -moz-animation: cssload-animball_two 2.2s infinite;
  width: 43px;
  height: 43px;
}
.cssload-internal {
  width: 43px;
  height: 43px;
  position: absolute;
}
.cssload-ballcolor {
  width: 19px;
  height: 19px;
  border-radius: 50%;
}
.cssload-ball_1,
.cssload-ball_2,
.cssload-ball_3,
.cssload-ball_4 {
  position: absolute;
  animation: cssload-animball_one 2.2s infinite ease;
  -o-animation: cssload-animball_one 2.2s infinite ease;
  -ms-animation: cssload-animball_one 2.2s infinite ease;
  -webkit-animation: cssload-animball_one 2.2s infinite ease;
  -moz-animation: cssload-animball_one 2.2s infinite ease;
}
.cssload-ball_1 {
  top: 0;
  left: 0;
}
.cssload-ball_2 {
  top: 0;
  left: 23px;
}
.cssload-ball_3 {
  top: 23px;
  left: 0;
}
.cssload-ball_4 {
  top: 23px;
  left: 23px;
}
@keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}
@-o-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}
@-ms-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}
@-webkit-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}
@-moz-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}
@keyframes cssload-animball_two {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(1.3);
  }
  100% {
    transform: rotate(720deg) scale(1);
  }
}
@-o-keyframes cssload-animball_two {
  0% {
    -o-transform: rotate(0deg) scale(1);
  }
  50% {
    -o-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -o-transform: rotate(720deg) scale(1);
  }
}
@-ms-keyframes cssload-animball_two {
  0% {
    -ms-transform: rotate(0deg) scale(1);
  }
  50% {
    -ms-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -ms-transform: rotate(720deg) scale(1);
  }
}
@-webkit-keyframes cssload-animball_two {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(1);
  }
}
@-moz-keyframes cssload-animball_two {
  0% {
    -moz-transform: rotate(0deg) scale(1);
  }
  50% {
    -moz-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -moz-transform: rotate(720deg) scale(1);
  }
}

/* User Content Header Start (Please Remove Here in Development)
--------------------------------------------------------------------------------------------------------*/
.content-header {
  padding: 15px 25px 15px 15px;
}
.content-header .left {
  float: left;
}
.content-header .search-block {
  position: relative;
}
.content-header .search-block .search-icon {
  position: absolute;
  top: 4px;
  left: 0;
  z-index: 10;
}
.content-header .search-block {
  margin-bottom: 0;
}
.content-header .search-block .form-control {
  transition: all ease 0.3s;
  width: 0;
  padding-left: 40px;
  opacity: 0;
  visibility: hidden;
}
.search-show .content-header .search-block .form-control {
  width: 250px;
  display: block;
  opacity: 1;
  visibility: visible;
}
.content-header .right {
  float: right;
}
.content-header a.icon-link {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.content-header .right a.icon-link:hover {
  background-color: rgba(147, 151, 155, 0.13);
}
.content-header a.icon-link img {
  display: block;
  height: 20px;
  width: 20px;
  margin: 10px auto 0;
}
.content-header .dropdown {
  display: inline-block;
}
.content-header .dropdown .dropdown-menu {
  min-width: 500px;
  height: 300px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.32);
  border: none;
  padding: 20px;
  margin: 0;
  border-radius: 3px;
}
.content-header .dropdown-toggle::after {
  display: none;
}
.content-header .dropdown-header {
  padding: 0 0 20px;
}
.content-header .dropdown-header h4 {
  font-size: 16px;
  line-height: 20px;
  color: #444444;
  float: left;
  margin-bottom: 0;
}
.content-header .dropdown-header a {
  float: right;
}
.content-header .dropdown-header a:hover {
  color: #001a31;
  text-decoration: none;
}

/* User Content Header END
--------------------------------------------------------------------------------------------------------*/

/* My Profile Link */
.nav-collapsed .profile-link {
  left: 0;
  top: -59px;
}

/* Login BG
--------------------------------------------------------------------------------------------------------*/
.loginbg1 .footer p,
.loginbg2 .footer p,
.loginbg3 .footer p,
.loginbg1 .footer a,
.loginbg2 .footer a,
.loginbg3 .footer a {
  color: #fff;
}
.loginbg1 .sse-logo,
.loginbg2 .sse-logo,
.loginbg3 .sse-logo {
  background: url(../img/sse-footer-w.svg) center center no-repeat !important;
}

/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/
@media (max-width: 1030px) {
  .wrapper {
    padding-left: 0;
  }

  /*html.nav-open { height: auto; }*/
  .sidebar {
    transition: all ease 0.3s;
    left: -220px;
    top: 65px;
    position: fixed;
    display: block;
    z-index: 1000;
    box-shadow: none;
    overflow: auto;
  }
  .nav-open .sidebar {
    left: 0;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  }
  .nav-backdrop {
    top: 65px;
    display: block;
  }
  .nav-open .nav-backdrop {
    opacity: 0.5;
    visibility: visible;
  }
  .nav-open {
    overflow: hidden;
  }
  .header {
    left: 0;
    height: 65px;
    width: 100%;
    max-width: 100% !important;
  }
  .wrapper {
    display: block;
    padding-top: 65px;
  }
  .nav-open .wrapper {
  }
  .footer {
    left: 0;
  }
  .main-content h1 {
    font-size: 28px;
  }
  .page-title h1 {
    padding-top: 8px;
  }
  .btn-logout {
    left: -220px;
  }
  .nav-open .btn-logout {
    left: 0;
  }
  .nav-toggle {
    height: 65px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding-top: 58px;
    padding-bottom: 90px;
  }
  .nav-toggle {
  }
  .sidebar .main-navigation > li > a {
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .footer {
    text-align: center;
    height: 90px;
  }
  .sse-logo {
    float: none;
    margin-top: 7px;
    width: 100%;
  }
  .footer > p {
    float: none;
    padding: 8px 0 0 0;
    line-height: 1.2;
  }
  .main-content h1 {
    font-size: 24px;
  }
  .field-mandatory {
    position: static;
    text-align: right;
    margin: -5px 0 10px 0;
  }
  .modal-title {
    font-size: 20px;
    padding: 10px;
  }
  .modal-header .close {
    top: 8px;
    right: 8px;
  }
  .back-button {
    display: inline-block;
    width: 32px;
    height: 32px;
    vertical-align: -8px;
  }
  .btnspace {
    padding-top: 0;
    padding-bottom: 5px;
  }
  h2.title2 {
    font-size: 20px;
  }
  h3.title3 {
    font-size: 16px;
    padding: 0 0 12px 0;
  }
  h4.title4 {
    font-size: 14px;
    padding: 0 0 12px 0;
  }
  .right-module-box {
    margin-bottom: 20px;
  }
  .rm-body {
    height: auto;
    overflow: visible;
  }
  .modal-md {
    max-width: none;
  }
  .modal-md {
    max-width: 575px;
  }
  .loginbox {
    padding: 25px;
  }
  .loginlogo {
    padding-bottom: 15px;
  }
  .loginbox h2 {
    font-size: 19px;
  }
  .alert {
    font-size: 14px;
    padding: 15px 40px 15px 50px !important;
  }
  .loginlogo img {
    max-width: 85%;
  }
  .loginbox .back-button {
    left: 5px;
    top: 10px;
  }
  .login-outer {
    padding: 15px 15px 90px 15px;
  }
  .error-outer {
    padding: 15px 15px 100px 15px;
  }
  .errorlogo {
    padding-bottom: 15px;
  }
  .errorlogo img {
    max-width: 85%;
  }
  .error-block h1 {
    padding: 30px 0 20px 0;
    font-size: 24px;
  }
  /* .btn {
    padding: 13px 15px 11px 15px;
  } */
  .ic-btn {
    padding-left: 42px;
  }
}

/* NEW ============
--------------------------------------------------------------------------------------------------------*/
.back-button.mt-1 {
  margin-top: 6px !important;
}
.content-body h1 {
  padding-bottom: 20px;
}
.width100 {
  max-width: 100%;
}
.width75 {
  max-width: 1150px;
}
.width50 {
  max-width: 825px;
}
.width25 {
  max-width: 450px;
}
.custom-scroll {
  overflow: hidden;
}
.touch-device .custom-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.analytics-order::-webkit-scrollbar {
  display: none;
  width: 0;  
}
.inner-body-wrapper {
  height: calc(100vh - 250px);
}
@media (max-width: 1030px) {
  .inner-body-wrapper {
    height: calc(100vh - 310px);
  }
}
@media (max-width: 767px) {
  .content-body h1 {
    padding-bottom: 0;
  }
  .content-body .field-mandatory {
    margin-bottom: 20px;
    display: block;
    text-align: left;
  }
  .inner-body-wrapper {
    height: calc(100vh - 330px);
  }

  /* .inner-body-wrapper { height: calc(100vh - 330px); overflow-y: auto !important; } */
}

/******************************* NEW CSS Related to Device ***************************************/

/*ADDNEW*/

.card {
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)!important;
}
.device-step {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  position: absolute;
  list-style: none;
}
.device-step li {
  position: relative;
  list-style-type: none;
  padding: 0 0 0 20px;
  margin: 0;
  display: inline-block;
}
.device-step li:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  width: 50px;
  height: 1px;
  background-color: #333;
}
.device-step > :first-child:before {
  display: none;
}
.device-step li a {
  position: relative;
  z-index: 10;
  text-decoration: none;
  padding-top: 6px;
  height: 42px;
  width: 42px;
  border: 1px solid #94979b;
  display: block;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  font-size: 18px;
  color: #666666;
  font-weight: 700;
}
.device-step li.active a {
  color: #fff;
}
.device-step li.filled a {
  background-color: #efefef;
}
.device-detail {
  background-color: #f5f5f5;
  padding: 5px 7px;
  margin-bottom: 20px;
}
.device-photo {
  width: 50px;
  height: 50px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  float: left;
}
.device-photo img {
  width: 48px;
  height: 48px;
}
.device-detail-body {
  padding: 13px 0 12px 100px;
}
.add-metadata .table th {
  padding: 6px 8px 8px 0;
  font-family: "MuseoSansW01-Rounded500", "PT Sans", sans-serif;
  font-weight: normal;
  border-top: none;
  border-bottom: 1px solid #d8d8d8;
  color: #000;
}
.add-metadata .table td {
  border: none;
  padding: 6px 8px 0 0;
}
.add-metadata .form-control {
  height: 36px;
  padding: 6px 6px;
}
a.grid-link {
  display: block;
  float: left;
  height: 36px;
  width: 36px;
  padding: 8px;
  border-radius: 50%;
}
a.grid-link:hover,
a.grid-link:focus {
  background-color: rgba(147, 151, 155, 0.13);
}
a.grid-link img {
  display: block;
  height: 20px;
  width: 20px;
}
.grid-action {
  width: 100px;
  text-align: center;
  white-space: nowrap;
}
.attribute-grid {
  margin: 0;
  color: #666666;
}
.attribute-grid th {
  padding: 6px 10px;
  vertical-align: middle;
  border-bottom: 1px solid #d8d8d8 !important;
  border-top: none;
  font-family: "MuseoSansW01-Rounded500", "PT Sans", sans-serif;
  font-weight: 500;
  color: #000;
}
.attribute-grid td {
  padding: 6px 10px;
  vertical-align: middle;
  border-bottom: 1px solid #efefef;
  border-top: 0px solid #efefef;
}
.attribute-grid td th,
.attribute-grid td td {
  border: none !important;
}
.inner-table > td {
  padding: 0;
  border: 0 !important;
}
.inner-table > td > div {
  border-bottom: 1px solid #efefef;
  padding-left: 95px;
}
.expcol-btn {
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid #666666;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-radius: 50%;
  margin-bottom: 5px;
}
.expcol-btn:focus {
  background-color: #f3f3f3;
}
.expcol-btn:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  top: 47%;
  left: 5px;
  background-color: #666;
}
.expcol-btn.collapsed:after {
  content: "";
  position: absolute;
  height: 12px;
  width: 2px;
  top: 5px;
  left: 47%;
  background-color: #666;
}
.btntopspace {
  padding-top: 25px;
}
.btn.btn-notext {
  width: 46px;
  padding-left: 0;
  min-width: 20px;
}
@media (max-width: 991px) {
  .device-detail-body {
    padding: 3px 0 6px 70px;
  }
}
@media (max-width: 767px) {
  .device-step {
    position: static;
    text-align: center;
    padding: 15px 20px 15px 0;
  }
  .btntopspace {
    padding-top: 0px;
  }
}

/* Responsive bootstrap tabs----------------------------------------------------------------------------------------------- */

.bootstrap-tab-heading {
  border-bottom: 2px solid #4099d4;
}
.bootstrap-tab-heading li {
  float: left;
  position: relative;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.bootstrap-tab-heading li > a {
  height: 54px;
  background-color: #f5f5f5 !important;
  border-radius: 0px !important;
  padding: 15px 25px 0 25px !important;
  margin-right: 2px !important;
  display: block;
  color: #3c3c3c !important;
  text-decoration: none;
  font-size: 16px;
}
.bootstrap-tab-heading li:hover > a {
  color: #fff !important;
}
.bootstrap-tab-heading li > a.active {
  color: #fff !important;
}

/* Device details large
--------------------------------------------------------------------------------------------------------*/
h2.title2 > .badge {
  background: #93979b;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 22px;
  min-width: 24px;
  padding: 0 6px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
}
.device-detail-large {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.device-detail-large .image-wrapper {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 108px;
  max-width: 108px;
  min-width: 108px;
  max-height: 108px;
}
.device-detail-large .image-wrapper > img {
  max-height: 80px;
  max-width: 80px;
}
.device-detail-large .content-wrapper {
  width: 100%;
  padding-left: 12px;
}
.device-detail-large .content-wrapper > h3 {
  padding-bottom: 8px;
}
.device-detail-large .content-wrapper > p {
  margin-bottom: 0;
}
.parameter-list {
  padding-bottom: 12px;
}
.parameter-list p {
  margin-bottom: 6px;
}
.tag-list-wrapper > .title {
  padding-bottom: 6px;
  display: block;
}
.tag-list {
  padding-bottom: 16px;
  color: #666666;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.tag-list > li {
  background: #f5f5f5;
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* NEW Input Table CSS */
.inputtable .table th {
  padding: 6px 8px 8px 0;
  font-family: "MuseoSansW01-Rounded500", "PT Sans", sans-serif;
  font-weight: normal;
  border-top: none;
  border-bottom: 1px solid #d8d8d8;
  color: #000;
}
.inputtable .table td {
  border: none;
  padding: 6px 8px 0 0;
}
.inputtable .form-control {
  min-width: 120px;
}
.inputtable {
  overflow-x: visible;
}

@media (max-width: 767px) {
  .inputtable {
    overflow-x: auto;
  }
}

.custom-accordion .card {
  border: none !important;
  padding: 0;
  margin: 0;
}
.custom-accordion .card h2 {
  position: relative;
  padding: 10px 50px 10px 0px;
  margin: 0;
  font-weight: 400;
}
.custom-accordion .card h2.collapsed {
  color: #333333;
}
.custom-accordion .card h2 span {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 6px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  border: 1px solid #3fa7ec;
}
.custom-accordion .card h2 span.collapsed {
  border: 1px solid #adadad;
}
.custom-accordion .card h2 span:after {
  content: "";
  position: absolute;
  top: 12px;
  right: 7px;
  width: 12px;
  height: 2px;
  background-color: #3fa7ec;
}
.custom-accordion .card h2 span.collapsed:after {
  background-color: #adadad;
}
.custom-accordion .card h2 span.collapsed:before {
  content: "";
  position: absolute;
  top: 7px;
  right: 12px;
  width: 2px;
  height: 12px;
  background-color: #adadad;
}
.custom-accordion .card .grid-action {
  z-index: 18;
  position: absolute;
  top: 13px;
  right: 43px;
}
.custom-accordion .card.pdtitle h2 {
  padding-right: 85px;
}

.accordion > .card {
  overflow: visible;
}

.topbtngroup {
  float: left;
}
.topbtngroup .btn-group {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.btn-group > .btn {
  border-radius: 50px;
  position: relative;
  width: 44px;
  height: 38px;
  margin-left: 0 !important;
}
.topbtngroup .btn-group > .btn.btn-light {
  background-color: #fff !important;
  border: none !important;
}
.topbtngroup .btn-group > .btn.btn-light:hover {
  background-color: #f5f5f5 !important;
}
.topbtngroup .btn-group > .btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  height: 38px;
}
.title-right a.icon-link {
  text-align: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  background: #fff;
  display: inline-block;
  height: 38px;
  width: 38px;
  padding-top: 8px;
  border-radius: 50%;
}
.title-right a.icon-link:hover,
.title-right a.icon-link:focus {
  background-color: #f5f5f5;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.title-right a.icon-link img {
  height: 20px;
}

/**************** NEW Apps and Dashboard Pages ****************/
.mbb-title-right {
  padding-right: 40px;
  position: relative;
}
.mbb-title-right a.grid-link {
  position: absolute;
  top: -10px;
  right: 0;
  padding: 11px;
}
.mbb-title-right a.grid-link img {
  height: 15px;
  width: 15px;
}

.dash-app-box {
  transition: all ease 0.3s;
  padding: 20px 20px 60px 20px;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  position: relative;
  height: calc(100% - 30px);
  min-height: 220px;
}
.dash-app-box .dap-bottom {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 0 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
}
.app-install,
.app-installed {
  text-decoration: none !important;
  display: none;
  cursor: pointer;
  position: relative;
  padding-left: 40px;
  font-weight: 700;
  font-size: 16px;
}
.app-install span,
.app-installed span {
  border: 2px solid #fff;
  position: absolute;
  left: 0;
  top: -2px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
}
.app-installed {
  color: #74b839;
}
.app-install:hover span,
.app-install:focus span {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
}
.dash-app-box:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
}
.dash-app-box:hover .app-install {
  display: inline-block;
}
.dash-box-installed .app-install {
  display: none !important;
}
.dash-box-installed .app-installed {
  display: inline-block !important;
}
.app-remove {
  display: none;
  position: relative;
  margin-top: -2px;
  width: 32px;
  height: 32px;
  border: 1px solid #b5b5b5;
  border-radius: 50%;
}
.app-remove:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 7px;
  width: 16px;
  height: 3px;
  background-color: #8c8c8c;
}
.app-remove:hover,
.app-remove:focus {
  background-color: #b5b5b5;
}
.app-remove:hover:after,
.app-remove:focus:after {
  background-color: #fff;
}
.dash-box-installed:hover .app-remove {
  display: inline-block;
}
.app-logo {
  height: 52px;
  margin-bottom: 10px;
  overflow: hidden;
}
.app-logo img {
  max-height: 52px;
  max-width: 100%;
}

.dash-app-box h3 {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  padding: 0;
}
.dash-app-box .app-version {
  display: block;
  color: #888888;
  font-size: 13px;
  padding: 3px 0 5px 0;
}
.dash-app-box p {
  color: #666;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 16px;
}

a.dash-link {
  display: none;
  margin-left: 4px;
  height: 32px;
  width: 32px;
  text-align: center;
  border: 1px solid #b5b5b5;
  padding-top: 7px;
  border-radius: 50%;
}
a.dash-link:hover,
a.dash-link:focus {
  background-color: rgba(147, 151, 155, 0.13);
}
a.dash-link img {
  display: block;
  height: 16px;
  margin: 0 auto;
}

.dash-app-box:hover a.dash-link {
  display: inline-block;
}
/*.dash-box-installed a.dash-link{ display: none !important }*/
.dash-box-installed:hover a.dash-link.editlink {
  display: inline-block !important;
}
.import-dashboard-link {
  color: #004687;
  padding-top: 40px;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  display: block;
  text-align: center;
  height: 100%;
}
.import-dashboard-link img {
  height: 40px;
  display: block;
  margin: 0 auto 15px auto;
}

.btn-menu {
  min-width: 48px;
  width: 48px; /*border: 1px solid #d2d2d2 !important;*/
  padding: 0;
  border-radius: 50%;
}
.btn-menu img {
  margin-top: 12px;
  height: 22px;
}
.btn-menu.dropdown-toggle:after {
  display: none;
}
.cus-dropdownmenu {
  border-radius: 8px;
  padding: 5px !important;
  border: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
.cus-dropdownmenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cus-dropdownmenu ul li {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.cus-dropdownmenu ul li a {
  color: #666;
  text-decoration: none !important;
  display: block;
  padding: 10px 15px 10px 44px;
  margin: 0;
}
.cus-dropdownmenu ul li a:hover,
.cus-dropdownmenu ul li a:focus {
  color: #111;
  background-color: #f5f5f5;
}
.cus-dropdownmenu ul li a img {
  height: 24px;
  left: 11px;
  top: 10px;
  position: absolute;
}

a.dash-leftlink {
  margin-left: 4px;
  height: 32px;
  width: 32px;
  text-align: center;
  border: 1px solid #b5b5b5;
  padding-top: 7px;
  border-radius: 50%;
}
a.dash-leftlink:hover,
a.dash-leftlink:focus {
  background-color: rgba(147, 151, 155, 0.13);
}
a.dash-leftlink.active {
  background-color: #74b839;
  border-color: #74b839;
}

/**************** Feeds ****************/
.feed-subtext {
  font-size: 16px;
}
@media (max-width: 767px) {
  .feed-subtext {
    margin-top: 0;
    font-size: 14px;
  }
}

/**************** Custom Badge ****************/
.custombadge .badge {
  margin-top: -2px;
  border-radius: 20px;
  padding: 5px 15px 6px 15px;
  font-size: 13px;
  font-weight: 400;
}

.login-bg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed !important;
}

.btn-outline-primary {
  /* background-color: #e8deec; */
  /* border-color: #e8deec;
  color: ""; */
}
/* .btn-info {
  color: #fff;
  background-color: #2f4cdd;
  border-color: #2f4cdd;
}

.btn-primary {
  color: #fff;
  background-color: #dd2f6e;
  border-color: #dd2f6e;
}

.btn-light {
  background: #f4f4f4;
  border-color: #f4f4f4;
}

.light.btn-secondary {
  background-color: #e8deec;
  border-color: #e8deec;
  color: #392540;
}

.light.btn-success {
  background-color: #e7faec;
  border-color: #e7faec;
  color: #2bc155;
}

.light.btn-danger {
  background-color: #fde6e6;
  border-color: #fde6e6;
  color: #f35757;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color:  !important;
  border-color:  !important;
}

.light.btn-danger {
  background-color: #fde6e6;
  border-color: #fde6e6;
  color: #f35757;
} */

.btn-outline-primary{
  /* border-color: rgb(0, 171, 85);
  color: rgb(0, 171, 85); */
}

.btn-outline-primary:hover{
  /* background-color: rgb(0, 171, 85);
  border-color: rgb(0, 171, 85);
  color: #fff !important */
}

.recharts-responsive-container {
  min-height: 300px !important;
}

.card {
  border-radius: 9px;
  /* border-radius: 0.75rem !important; */
  border: initial;
  background: #fff;
}

.btn {
  /* padding: 0.938rem 1.5rem; */
  border-radius: 9px;
  font-weight: 500;
  /* font-size: 1rem; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

/* MODAL OVERRIDE */

.modal-backdrop {
  z-index: 9998!important;
}
.modal {
  z-index: 9999!important;
}
.modal-header {
  align-items: center;
  padding: 6px 13px !important;
  position: sticky!important;
  top: 0;
  z-index: 2;
  background: #fff!important;
}

.modal-content {
  border-radius: 0.75rem;
}

.modal-footer {
  padding: 1rem 7px;
  z-index: 2;
}

.booked_table {
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 16px;
  color: #fe5f55;
  border-top: 3px solid #fe5f55;
  border-radius: 9px;
  overflow:hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.table-view-action-button {
  position: absolute;
  bottom: -12px;
  display: flex;
   justify-content: space-around;
   left: 0;
   right: 0;
}
.empty_table {
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 16px;
  color: #76b041;
  border-top: 3px solid #76b041;
  border-radius: 9px;
  overflow:hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.dinin_booked{
  background-color: #ffe4e6 !important;
}

.container{
  position: relative;
}
.cake{
 
  position:absolute;
    width:6rem;
    top:20px;
    left:30rem;
}
.slice{
  position:absolute;
     width:5rem;
     bottom: 40px;
     left: 34rem;
}
.pizza{
  position:absolute;
     width:5rem;
     top:6rem;
     right: 7rem;
}
.coffee{
  position:absolute;
     width:6rem;
     bottom: 50px;
     right: 5rem;
}
@media (min-width:768px) and (max-width: 950px){
  .cake{
    width:5rem;
    top:20px;
    left:6rem;
  }
  .slice{
    width:4rem;
    bottom: 30px;
    left: 9rem;
  }
  .coffee{
    width:5rem;
     bottom: 40px;
     right: 5rem;
  }
}
@media (min-width:425px) and (max-width: 767px){
  .cake{
    width:5rem;
    top:20px;
    left:3rem;
  }
  .slice{
    width:4rem;
    bottom: 30px;
    left: 5rem;
  }
  .coffee{
    width:5rem;
     bottom: 40px;
     right: 2rem;
  }
  .pizza{
   
    top:5rem;
    right: 4rem;
  }
}
@media (max-width:424px){
  .cake{
    width:4rem;
    top:20px;
    left:3rem;
  }
  .profile-container {
    padding: 0!important;
  }
  .Mui-disabled.MuiTabs-scrollButtons {
    display: none!important;
  }
   .slice{
    width:4rem;
    bottom: 5rem;
    left: 4rem;
  }
  .coffee{
    width:4rem;
     bottom: 2rem;
     right: 2rem;
  }
  .pizza{
    top:3rem;
    right: 4rem;
  }
  .mobile-view-table-size {
    flex: 0 0 45%!important;
    height: 140px!important;
  }
  .dine-in-table  {
    padding-bottom: 8px!important;
  }
  .table-view-action-button {
    bottom: 0!important;
    left: 0!important;
    right: 0!important;
    position: relative!important;
  }
  .mobile-view-table {
    padding: 0!important;
  }
  .mobile-view-table-flex {
    justify-content: center!important;
  }
}

.custom-material-table-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  top: 0;
  background: #fff;
  z-index: 16;
  /* border-radius: 5px; */
  /* border: 1px solid #eee; */
  /* box-shadow: -1px 5px 5px 1px #eee; */
}

body.swal2-height-auto {
  height: 100%!important;
}
body.swal2-shown.swal2-height-auto {
  height: 100%!important;
}
.custom-material-table-toolbar .MuiToolbar-regular {
  flex: 1 1 auto;
}
.MuiTooltip-popper {
  z-index: 9999999999999999999999999999999999999999!important
}

div[class*="MTableToolbar-title-"],
div[class*="MTableToolbar-searchField-"] {
  width: 100%;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: none!important;
}

.modal-content{
  padding: 10px;
}

