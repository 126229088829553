.callp{
    display: flex;
}
.call{
    width: 10px;
    height: 15px;
    margin-right: 4px;
}
.callp p{
    font-weight: 500;
font-size: 12px;
color: #016FFC;
}
.card1{
    width: 100%;
    padding: 12px;
    border: 1px solid #D7D7D7 !important;
border-radius: 9px;
}

.dots{
    display: flex;
}
