.order-bill-table {
  thead {
    position: sticky;
    top: 0;
    background: #eee;
    z-index: 2;
    // background: #e8deec;
    box-shadow: 2px 2px 4px 0 #6645746b;
    border-radius: 6px;
    font-weight: 500;
    font-family: Oxygen, MuseoSansW01-Rounded500 !important;

    th {
      padding: 5px 17px;
      color: #888686;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  tbody {
    position: relative;

    .no-item-box {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .add-edit-option {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;

    .food-text {
      font-size: 16px;
      font-weight: 300;
      flex: 1;
      word-break: break-all;
      cursor: pointer;
    }
  }

  .icon-button {
    padding: 2px;
  }

  .icon {
    font-size: 16px;
  }

  .total-price-td {
    display: flex;
    align-items: center;

    .price-box {
      flex: 1;
      font-size: 13px;
      font-weight: 300;
      text-align: right;
    }
  }
}

.order-bill-table-mobile {
  thead {
    position: sticky;
    top: 0;
    background: #eee;
    z-index: 3;
    box-shadow: 2px 2px 4px 0 #6645746b;
    border-radius: 3px;
    font-weight: 200;
    font-family: Oxygen, MuseoSansW01-Rounded500 !important;

    th {
      padding: 2px 15px;
      color: black;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  tbody {
    position: relative;

    .no-item-box {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .add-edit-option {
    display: flex;
    align-items: center;
    justify-content: center;

    .food-text {
      font-size: 16px;
      font-weight: 300;
      flex: 1;
      word-break: break-all;
      cursor: pointer;
    }
  }

  .icon-button {
    padding: 2px;
  }

  .icon {
    font-size: 20px;
  }

  .total-price-td {
    display: flex;
    align-items: center;

    .price-box {
      flex: 1;
      font-size: 16px;
      font-weight: 300;
      text-align: right;
    }
  }
}

.order-total-section {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  width: 100%;
  text-align: right;
  font-weight: 600;
  font-family: Oxygen, MuseoSansW01-Rounded500 !important;
}

.mobile-view-kot-action-btn, .mobile-view-bill-action-btn, .mobile-view-settle-action-btn, .mobile-view-save-action-btn {
  flex: auto;
  margin: 1px 0px;
}

.card-bill {
  height: 100%;
  padding: 7px!important;
  min-height: 100%;
  position: relative;
}

.card-bill-header {
  // position: absolute;
  // top: 0px;
  // left: 10px;
  // height: 70px;
  // right: 10px;
}

.card-bill-body {
  position: absolute;
  top: 91px;
  bottom: 163px;
  left: 0px;
  right: 0px;
  overflow: auto;
}
.card-bill-footer {
  position: absolute;
  bottom: 63px;
  min-height: 100px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0px -3px 4px 0 rgb(102 69 116 / 42%);
  border-radius: 8px;
}

.card-bill-button-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0px;
  height: 58px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.order-detail-screen {
  width: 100%;
  height: 100%;
  padding: 3px !important;
  margin: 0px !important;
}




.smart-rest-order-menu {
  width: 100%;
  padding: 0px !important;
}

.sf-billing-mobile-view {
  height: 100%;
  padding: 0px !important;
}

.billing-details-section {
  padding: 0px !important;
  height: 100%;
  .table-responsive-sm {
    height: 100%;
  }
}

.billing-details-section-mobile {
  padding: 0px !important;
  height: 30vh;
  .table-responsive-sm {
    height: 100%;
    margin-top: 5px;
  }
}

.food-item-list,
.category-list-col {
  padding-left: 10px;
  position: relative;
}
.category-list-col {
  padding-right: 0px;
}

.smart-rest-order-menu {
  position: relative;
}

.food-item-list-container,
.category-section-mobile {
  position: absolute;
  overflow: auto;
  height: 100%;
}

.food-item-list-container {
  width: calc(100% - 29px);
  padding: 0px 15px;
}
.category-section-mobile {
  width: 100%;
}

.sf-mobile-tablet-category {
  height: 100%;
  max-height: calc(100% - 7px);
}

.mobile-view-billing {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.order-bill-one-screen-layout-mobile-button {
    text-align: center;
    background: #fff!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 7px;
}

.order-bill-mobile-button {
  text-align: center;
  background: #fff!important;
  position: absolute;
  bottom: 62px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 7px;

  .cart-button {
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 28%);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    // padding: 9px;
    font-size: 15px;
    background: transparent;
    margin-right: 0.5rem;
    max-width: 60%;
    color: black;
    border-color: black;
    background-color: white;
  }
}

.mobile-view-button-shadow {
  height: 56px !important;
  // padding: 7px !important;
  // font-size: 10px !important;
  box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 28%);
}

.food-item-name-box {
  border-top: 2px solid;
  text-align: center;
  /* padding: 1px; */
  border-radius: 10px;
  padding-top: 8px;
  overflow:hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.startdate {
  margin: 0px !important;
  width: 155px;
  margin-right: 5px !important;
  display: flex;
  justify-content: flex-start !important;
}
.enddate {
  width: 155px;
  margin: 0px;
}



.history-filter-button {
  display: flex;
  align-items: center;
  overflow: auto;
  margin-bottom: 8px;
  margin-top: 5px;

  button {
    // width: auto;
    // height: 50px;
    // padding: 0px 14px;
    // font-size: 15px;
    margin-right: 5px;
    // background-color: white;
    // white-space: normal !important;
  }
}

.analytics-order::-webkit-scrollbar {
  display: none;
  width: 0;
}


.MuiTabScrollButton-horizontal {
  display: none !important;
  visibility: hidden !important;
}

.MuiTabScrollButton-root.Mui-disabled {
  display: none !important;
  visibility: hidden !important;
}

.round-off-price-text {
  color: grey;
  font-weight: 300;
}

// REFINE MEDIA QUERY SECTION//

@media (min-width: 350px) and (max-width: 740px) {
  .startdate {
    width: 145px !important;
    margin-right: 8px !important;
  }
  .enddate {
    width: 145px !important;
    margin: 0px !important;
  }
}

@media (min-width: 520px) and (max-width: 750px) {
  .tablet-view {
    right: 165px;
    float: right;
    position: absolute;
    top: 8px;
  }
}

@media (min-width: 520px) and (max-width: 720px) {
  .tablet-enddate {
    margin-left: 5px;
    right: 0px;
    float: right;
    position: absolute;
    top: 8px;
  }
}

@media (min-width: 520px) and (max-width: 990px) { 
  .order-detail-screen {
    height: 95%!important;
  }

  .order-bill-mobile-button {
    background: #fff!important;
  }
}

@media only screen and (max-width: 576px) {
  .history-filter-button {
    position: unset;
    overflow: auto;
    height: 100%;
    margin: 5 0px;
  }
  .history-filter-button::-webkit-scrollbar {
    display: none;
    width: 0;
  }  
}


@media (min-width: 700px) and (max-width: 1050px) {
  .tablet-view {
    right: 165px;
    float: right;
    position: absolute;
    top: 15px;
  }
}

@media (min-width: 730px) and (max-width: 1040px) {
  .tablet-enddate {
    right: 0px;
    float: right;
    position: absolute;
    top: 15px;
  }
}

@media (max-width: 990px) { 
  
  .sf-mobile-tablet-category{
    max-height: calc(100% - 67px)!important;
  }

  .card-bill-body {
    top: 137px!important;
  }
}

@media only screen and (max-width: 990px) { 
  .MuiDrawer-paperAnchorRight {
    width: 90% !important;
  }
}