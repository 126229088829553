.para{
    margin-bottom: 4px;
    font-weight: 600;
font-size: 13px;
}
.card1 img{
    margin-right: 10px;
}
.card1 h6{
    margin-bottom: 5px;
}
.veg{
    width:20px;
    height: 22px;
}
.rupee{
    font-weight: 500;
    font-size: 18px;
    color: #76B041;
}
.para2{
    margin-top: 8px;
    margin-bottom: 10px;
}