  .mytable td,
  .mytable th,
  .mytable tr,
  table.mytable {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-collapse: collapse;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    font-family: 'Calibri', serif!important;
  }

  .print-container{
    display: block!important;
    width: 58mm!important;
    min-width: 58mm!important;
    height: 500px!important;
    padding: 1.5mm!important;
    font-family: 'Calibri', serif!important;
  }

  .print-container h5, .print-container p,  .print-container h2 {
    font-family: 'Calibri', serif!important;
    font-size: bold!important;
  }
 
  .mytable td.itemName,
  .mytable th.itemName {
    width: 75px;
    max-width: 75px;
  }

  .mytable td.isVeg,
  .mytable th.isVeg {
     width: 40px;
     max-width: 40px;
     word-break: break-all;
   }
  
 .mytable td.quantity,
 .mytable th.quantity {
    width: 30px;
    max-width: 30px;
    word-break: break-all;
  }
  
 .mytable td.price,
 .mytable th.price {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
  }
  
  .centered {
    text-align: center;
    align-content: center;
  }
  
  .print-container img {
    max-width: inherit;
    width: inherit;
  }
  
  @media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
  }
  @media print {
    @page { 
      size: auto;   /* auto is the initial value */

     }
  }

