.Qrimg {
  width: 20px;
  height: 20px;
}

.para {
  font-weight: 400;
  font-size: 12px;
  color: #8e8e8e;
}

.para span {
  color: #fe5f55;
}

.btns {
  padding-inline: 11px;
  height: 23px;
  margin-right: 5px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  background: none;
  color: #76b041;
}
.btnr {
  padding-inline: 11px;
  height: 23px;
  margin-right: 5px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  background: none;
  color: #ff3838;
}

.tale {
  font-weight: 600;
  font-size: 15px;
}

.para2 {
  color: #8e8e8e;
}

.row2 {
  position: relative;
}

.card1 {
  width: 100%;
  padding: 12px;
  border: 1px solid #d7d7d7;
  border-radius: 9px;
}

.card2 {
  width: 100%;
  padding: 12px;
  border: 1px solid #f0b5b5 !important;
  border-radius: 9px;
  background-color: #fff2f2 !important;
}
