.foodcat-card {
  height: 100%;
  position: relative;
  margin: 0 !important;
  box-shadow: unset !important;
}

.food-catfix-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 15px;
}

.food-catfix-footer > div p {
  margin-bottom: 0;
}

.foodcatstep {
  width: 100%;
  background: #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.foodcategorysticky {
  width: 100%;
  margin-bottom: 10px;
  /* background: #fff; */
}

.foodcategorysticky > div h4 {
  font-size: 18px;
  margin: 0;
}
.backButton {
  margin: 5px;
  height: 30px;
}