.para{
  margin-bottom: 4px;
  font-weight: 600;
font-size: 13px;
}
.veg{
  width:20px;
  height: 22px;
}
.rupee{
  font-weight: 500;
font-size: 18px;
color: #76B041;
margin-left: 'auto';
}
.para2{
  margin-top: 8px;
  margin-bottom: 10px;
}

.categoryName{
  margin-top: 5px;
  word-break: break-all;
}
.cardCategory{
    padding: 5px 11px;
    margin-top: 10px!important;
    margin-bottom: 0px!important;
    border: 1px solid #5fa55f69;
}
.available-btn {
  position: absolute;
  top: 9px;
  background: #fff;
  right: 9px;
  border-radius: 6px;
}
@media only screen and (min-width: 350px) {
  .imgCategory {
    width: 100%;
    max-height: 5rem;
  }
}
